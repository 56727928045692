import api from "../axios_service.js";

export async function getClientes(filtros) {
  const response = await api.get(`/clientes${filtros}`);
  return response.data.result;
}

export async function getHistoricoVendas(filtros) {
  const response = await api.get(`/historico-vendas${filtros}`);
  return response.data.result;
}

export async function getListClientes(filtros) {
  const response = await api.post(`/listaclientes${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function searchClientes(filtros) {
  const response = await api.post(
    `/lista-clientes-completos${filtros ? filtros : ""}`
  );
  return response.data.result;
}



export async function clienteInfosVenda(id) {
  const response = await api.post(`/cliente-infos-venda/${id}`);
  return response.data.result;
}

export async function searchClientesVendas(filtros) {
  const response = await api.post(
    `/lista-clientes-completos-vendas${filtros ? filtros : ""}`
  );
  return response.data.result;
}

// Busca um cliente
export async function getCliente(id) {
  const response = await api.get(`/clientes/${id}`);
  return response.data.result;
}

// Atualiza um cliente existente
export function putCliente(id, cliente) {
  return api.post(`/clientes/${id}`, cliente);
}

// Cria um cliente novo
export function postCliente(cliente) {
  return api.post("/clientes/add", cliente);
}

// Get Images
export async function getImages(id) {
  const response = await api.get(`/clientes/${id}/imgs`);
  return response.data.cliente_imgs;
}

// verifica se é permitido deletar cliente
export async function allowDeleteCliente(id) {
  const response = await api.get(`/allow-delete-cliente/${id}`);
  return response.data.result;
}

//Deleta um cliente
export function deleteCliente(id) {
  return api.delete(`/delete-cliente/${id}`);
}
// GET CLIENTES ANIVERSARIANTES
export async function getBoxClientesAniversariantes() {
  const response = await api.get(`/clientes-box-aniversariantes`);
  return response.data.result;
}

// GET CREDITO CLIENTES
export async function fetchClienteCredito(id) {
  const response = await api.get(`/cliente-credito-disponivel/${id}`);
  return response.data.result;
}


// GET DATA LIMITE CREDITO CLIENTES
export async function fetchClienteDataLimiteCredito(id) {
  const response = await api.get(`/cliente-data-limite-credito/${id}`);
  return response.data.result;
}
//--------------------------------------------------------------------
// combobox
export async function searchClientesCombobox(filtros) {
  const response = await api.post(
    `/lista-clientes-completos-combobox${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function getClienteCombobox(id) {
  const response = await api.get(`/clientes-combobox/${id}`);
  return response.data.result;
}

export function putClienteCombobox(id, cliente) {
  return api.post(`/clientes-combobox/${id}`, cliente);
}

export function postClienteCombobox(cliente) {
  return api.post("/clientes-combobox", cliente);
}
